.override-label {
    position: absolute;
    right: 0px;
    background: brown;
    color: whitesmoke;
    padding: 5px;
}

.override-border {
    border-color: brown;
    border-width: 5px;
    border-style: solid;
}

.no-border {
    border: 0;
}
