@import "oj.os.settings";
@import "oj.os.mixins";

.errorPage {
    background-image: url("../../styles/images/third-section.jpg");

    .strip {
        height: var(--oj-core-spacing-3x);
        background-image: url("../../styles/images/colorStrip.png");
        min-width: 100%;
    }

    .container {
        padding-left: oj-px-to-rem(128px);
        padding-right: oj-px-to-rem(128px);
        min-height: 85vh;

        .title {
            font-size: var(--oj-typography-heading-2xl-font-size);
            font-weight: 400;
            line-height: var(--oj-core-spacing-12x);
            font-family: Georgia;
        }

        .title::after {
            margin-top: var(--oj-core-spacing-2x);
            display: block;
            content: "";
            height: var(--oj-core-spacing-1x);
            width: var(--oj-core-spacing-8x);
            background-color: var(--oj-palette-warning-rgb-90);
        }

        .subtitle {
            font-size: var(--oj-typography-heading-sm-font-size);
            font-weight: 700;
            line-height: var(--oj-core-spacing-8x);
        }

        .description {
            font-size: var(--oj-typography-subheading-xs-font-size);
            font-weight: 400;
            line-height: var(--oj-core-spacing-5x);
        }

        img {
            @if $responsiveQueryLargeDown and $responsiveQueryLargeDown != none {
                @media #{$responsiveQueryLargeDown} {
                    width: oj-px-to-rem(350px);
                }
            }
        }
    }
}