@import "oj.os.settings";

/* Drawer parent div selector */
div[class*="DrawerPopupBaseTheme_baseTheme"] {
  @media #{$responsiveQueryMediumUp} {
    width: oj-px-to-rem(512px);
  }

  #switchCompanyHeader {
    align-items: center;
    background-color: RGB(var(--oj-palette-neutral-rgb-30));
    border-bottom: 1px;
    border-bottom-style: solid;
    border-bottom-color: var(--oj-core-divider-color);
    gap: var(--oj-core-spacing-2x);
  }

  #switchCompanyBody {
    gap: var(--oj-core-spacing-4x);

    .company-card {
      &.current-selected {
        & > div {
          border: 2px solid var(--oj-core-border-color-selected);
        }
      }

      & > div {
        padding: var(--oj-core-spacing-3x) var(--oj-core-spacing-4x);
        gap: var(--oj-core-spacing-4x);

        #companyInfo {
          font-size: var(--oj-typography-body-sm-font-size);

          #companyName {
            font-size: var(--oj-typography-body-md-font-size);
            font-weight: bold;
          }

          #roleName {
            margin-top: var(--oj-core-spacing-2x);
          }

          #companyAddress {
            margin: var(--oj-core-spacing-4x) 0;
          }
        }

        #navigateArrow {
          display: inline-flex;
          align-items: center;
        }
      }
    }
  }
}
